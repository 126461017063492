<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" />
    <PageHeader :title="isEditing ? `${titleEdit}` : titleNew" />
    <v-card>
      <div class="row">
        <v-container class="pt-0">
          <v-form v-model="isFormValid">
            <v-container>
              <v-row>
                <v-col cols="4" sm="6" md="4" class="py-0">
                  <v-text-field
                    v-model.trim="nombre"
                    label="Nombre del formato"
                    dense
                    outlined
                    :rules="rules.required.concat([rules.requiredTrim(nombre)])"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  sm="6"
                  md="4"
                  class="d-flex py-0 justify-content-center"
                >
                  <v-checkbox
                    v-model="activo"
                    id="switch1"
                    class="mt-1"
                  ></v-checkbox>
                  <label for="switch1" class="mt-2">Activo</label>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-container>
            <div class="col-12 py-0">
              <TextEditor
                ref="text"
                :value.sync="content"
                :changed.sync="contentChanged"
              />
            </div>
            <v-container>
              <v-row>
                <v-col cols="3" class="py-0">
                  <v-select
                    v-model="variable"
                    label="Variables"
                    :items="variables"
                    item-text="nombre"
                    item-value="var_id"
                    dense
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-btn color="primary" @click="addVariable">
                  Insertar
                </v-btn>
              </v-row>
              <v-row class="pr-4 pl-4 pt-4" justify="end">
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  @click="saveContent"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import TextEditor from "@/components/textEditor/TextEditor.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "NuevoFormatoPdf",
  components: {
    PageHeader,
    TextEditor,
    GoBackBtn
  },
  data() {
    return {
      routeToGo: "ConfiguracionFormatosPdfActa",
      titleNew: enums.titles.NUEVO_FORMATO_PDF,
      titleEdit: enums.titles.EDITAR_FORMATO_PDF,
      content: "",
      contentChanged: false,
      rules: rules,
      isFormValid: false,
      nombre: null,
      activo: false,
      variable: null,
      pdfTemplateId: null,
      variables: [],
      cursorLocation: null,
      codigo: "ACTAFISC"
    };
  },
  created() {
    this.getVariables();
    if (this.$route.params.formato) {
      this.setFormato();
    }
  },
  computed: {
    isEditing() {
      return this.$route.params.formato;
    }
  },
  methods: {
    ...mapActions({
      fetchVariables: "configuracion/fetchVariables",
      savePdf: "configuracion/savePdf",
      setAlert: "user/setAlert"
    }),
    async getVariables() {
      const resp = await this.fetchVariables(this.codigo);
      this.variables = resp;
    },
    setFormato() {
      const template = this.$route.params.formato;
      this.pdfTemplateId = template.pdfTemplateId;
      this.content = template.htmlContent;
      this.nombre = template.nombre;
      this.activo = template.activo;
    },
    async saveContent() {
      let res = null;
      if (this.content.trim() === null || this.content.trim() === "") {
        this.setAlert({
          type: "warning",
          message: "El cuerpo del Pdf está vacío"
        });
      } else {
        const data = {
          pdfTemplateId: this.pdfTemplateId,
          nombre: this.nombre,
          codigo: this.codigo,
          activo: this.activo,
          htmlContent: this.content
        };
        res = await this.savePdf(data);
      }
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        setTimeout(() => {
          this.$router.push({ name: this.routeToGo });
        }, 2000);
      }
    },
    async addVariable() {
      this.variable = this.variables.find(
        x => x.nombre === this.variable
      ).variable;
      tinymce.activeEditor.execCommand(
        "mceInsertContent",
        false,
        this.variable
      );
    }
  }
};
</script>
<style lang="scss" scoped></style>
